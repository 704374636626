.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.validationErr{
  color:red;
}

.approved_cls{
  color:green;
}

.rejected_cls{
  color:red;
}

.swal2-title{
  color:#10161c!important;
}

.loginLogo{
  /* background: #11161e; */
    padding: 10px;
    border-radius: 13px;
}
.admin-login .form-control {
  background: #000;
  color: #fff;
}
.admin-login .form-control:hover, .admin-login .form-control:focus {
  background: #000;
}

/* .loginLogo img{
  width: 40%;
} */
.table_filter{
  float: right;
}


.as-react-table table thead {
  background: #131720;
}
.pagination li.page-item {
  line-height: 5px;
}
.as-react-table {
  overflow-x: auto;
}
/* width */
.as-react-table::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.as-react-table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.as-react-table::-webkit-scrollbar-thumb {
  background: #c6229f; 
  border-radius: 10px;
}

/* Handle on hover */
.as-react-table::-webkit-scrollbar-thumb:hover {
  background: #c6229f; 
}
.table-foot {
  margin-bottom: 11px;
}

input.form-control.input-profile.p-1 {
  padding: 10px 10px 10px 10px !important;
}

.box.profile {
  padding: 10px 10px 10px 10px !important;
  /* box-shadow: 0px 1px 8px 0px; */
}

.nftImg{
  height: 120px;
  width: 150px;
}